<template>
  <div class="listCardItem" :class="listCardItemClassJudge">
    <div class="cardHeaderRow">
      <div v-if="isShowScore" class="ScoreColor" :class="scoreChangeJudge">
        {{ liveScoreText }}
      </div>
      <div class="playMethodName">
        {{ showBetTitle(displayData.showBetTitle) }}
      </div>
      <div class="playMethodNameSupport" :class="playMethodNameSupportChangeJudge">
        {{ displayData.showCutLine }}
      </div>
      <div class="at"> @ </div>
      <div
        v-if="childIndex === 0"
        class="playBetOdd"
        :class="playBetOddClassJudge(cartData.OriginShowOdd, displayData.showOdd)"
      >
        <template v-if="cartData.betResult?.PayoutOdds">
          {{ showOddValue2(cartData.betResult.PayoutOdds) }}
        </template>
        <template v-else>
          {{ showOddValue(displayData.showOdd) }}
        </template>
      </div>
      <div
        v-if="childIndex === 1"
        class="playBetOdd"
        :class="playBetOddClassJudge(cartData.OriginShowOdd, displayData.showOdd)"
      >
        {{ showOddValue(displayData.showOdd2) }}
      </div>
      <i
        v-if="panelMode !== PanelModeEnum.result"
        class="el-icon-close"
        :style="isShowBlackMask ? 'color:white;' : ''"
        @click="cancelSingleHandler(cartData.GameID)"
      />
    </div>
    <div class="cardContentBlock" style="position: relative">
      <div v-if="cartData.LiveTVLink" class="livetv" @click="openLiveTV(cartData.LiveTVLink)"
        ><img src="@/assets/img/tv.png" :title="cartData.LiveTV"
      /></div>
      <div class="chart" @click="showOddsHistory"><img src="@/assets/img/chart.png" /></div>
      <template v-if="displayData.wagerGrpLabel !== ''">
        <div class="cardContentBlockRow">
          {{ displayData.showGameTypeLabel }}
        </div>
        <div class="cardContentBlockRow">
          {{ cartData.LeagueNameStr }}
        </div>
      </template>
      <template v-else>
        <div class="cardContentBlockRow">
          {{ `${displayData.showGameTypeLabel} - [${cartData.LeagueNameStr}]` }}
        </div>
      </template>
      <div class="cardContentBlockRow">
        <template
          v-if="
            cartData.hasOwnProperty('HomeTeamStr') &&
            cartData.hasOwnProperty('AwayTeamStr') &&
            cartData.AwayTeamStr === '.'
          "
        >
          <div class="teamRow">
            {{ cartData.HomeTeamStr }}
          </div>
        </template>
        <template
          v-else-if="
            cartData.hasOwnProperty('HomeTeamStr') &&
            cartData.hasOwnProperty('AwayTeamStr') &&
            cartData.HomeTeamStr === '.'
          "
        >
          <div class="teamRow">
            {{ cartData.AwayTeamStr }}
          </div>
        </template>
        <!-- 彩球 -->
        <template v-else-if="[83].includes(cartData.CatID)">
          <template v-if="cartData.HdpPos === 0">
            <div class="cardContentBlockRowText">
              {{ cartData.HomeTeamStr }}
            </div>
          </template>
          <template>
            <div v-if="cartData.HdpPos === 1" class="cardContentBlockRowText">
              {{ cartData.HomeTeamStr }}
            </div>
            <div v-if="cartData.HdpPos === 2" class="cardContentBlockRowText">
              {{ cartData.AwayTeamStr }}
            </div>
          </template>
        </template>
        <!-- 單隊總分 -->
        <template v-else-if="[131, 132].includes(cartData.WagerTypeID)">
          <div v-if="cartData.HdpPos === 1" class="cardContentBlockRowText">
            {{ cartData.HomeTeamStr }}
          </div>
          <div v-else-if="cartData.HdpPos === 2" class="cardContentBlockRowText">
            {{ cartData.AwayTeamStr }}
          </div>
        </template>
        <template v-else>
          <div v-if="cartData.hasOwnProperty('HomeTeamStr')" class="cardContentBlockRowText">
            {{ cartData.HomeTeamStr }}
          </div>
          <div v-if="cartData.hasOwnProperty('AwayTeamStr')" class="cardContentBlockRowText">
            <template v-if="cartData.HomeTeamStr && cartData.AwayTeamStr"> vs </template>
            {{ cartData.AwayTeamStr }}
          </div>
        </template>
      </div>
      <div v-if="childIndex === 0" class="cardContentBlockRow">
        <div
          v-if="
            panelMode === PanelModeEnum.lock ||
            panelMode === PanelModeEnum.result ||
            cartData.isSuccessMode
          "
          class="lockRowInfo"
        >
          <div class="infoItem">
            <div class="infoItemTitle"> {{ $t('Common.Bet') }} : </div>
            <div class="infoItemVal">
              {{ toCurrency(cartData.betAmount) }}
            </div>
          </div>
          <div class="infoItem">
            <div class="infoItemTitle"> {{ $t('Common.CanWin') }} : </div>
            <div class="infoItemVal">
              <template v-if="cartData.betResult?.PayoutOdds">
                {{ toCurrency(cartData.betAmount * cartData.betResult.PayoutOdds) }}
              </template>
              <template v-else>
                {{ toCurrency(cartData.winAmount) }}
              </template>
            </div>
          </div>
        </div>
        <div v-else class="inputRow">
          <div class="input-wrap">
            <input
              ref="BetAmountInput"
              class="input"
              :class="{
                gray: focusTarget === 2,
                redInputOutLine: isShowMinText || isShowMaxText || isMaxFixed || isMinFixed,
              }"
              type="number"
              v-model="cartData.betAmount"
              :max="cartData.BetMax"
              :min="cartData.BetMin"
              :placeholder="
                cartData.BetMin !== null &&
                cartData.BetMax !== null &&
                cartData.BetMax !== 500001 &&
                cartData.BetMax !== 500002 &&
                cartData.BetMax !== 500003
                  ? cartData.BetMin + '-' + cartData.BetMax
                  : ''
              "
              :readonly="MOBILE_APP"
              @focus="onInputFocus('betAmount')"
              @blur="betAmountBlur(cartData.GameID)"
              @click="onCardInputClick()"
              @input="inputRowItemChangeHandler(cartData)"
            />
            <span
              class="btnClearValue"
              v-if="cartData.betAmount"
              @mousedown="doCleaning()"
              @click="clearItemInputByIndex(cartIndex)"
            >
              <i class="el-icon-error"></i>
            </span>
          </div>
          <div class="input-wrap">
            <input
              class="input"
              :class="{ gray: focusTarget === 1 }"
              type="number"
              v-model="cartData.winAmount"
              :placeholder="$t('Common.CanWinMoney')"
              :readonly="MOBILE_APP"
              @focus="onInputFocus('winAmount')"
              @blur="winAmountBlur(cartData.GameID)"
              @click="onCardInputClick()"
              @input="inputRowItemWinAmountChangeHandler(cartData, cartIndex)"
            />
            <span
              class="btnClearValue"
              v-if="cartData.winAmount"
              @mousedown="doCleaning()"
              @click="clearItemInputByIndex(cartIndex)"
            >
              <i class="el-icon-error"></i>
            </span>
          </div>
          <div
            class="submitBtn"
            v-if="MOBILE_APP && isControlByBetSingle"
            @click="$emit('MobileListItemSubmitBet')"
          >
            {{ $t('Common.SubmitBet') }}
          </div>
        </div>
      </div>
      <div
        v-if="
          panelMode === PanelModeEnum.normal &&
          childIndex === 0 &&
          cartData.BetMaxEvt !== 500001 &&
          cartData.BetMaxEvt !== 500002 &&
          cartData.BetMaxEvt !== 500003
        "
        class="cardContentBlockRow limitText"
      >
        {{ $t(cartData.ctMinus1 === 1 ? 'ListCardItem.BetLimit' : 'ListCardItem.BetLimit2') }} : {{ toCurrency(cartData.BetMax2) }}
      </div>
      <div v-if="cartData.APIMax === 1" class="cardContentBlockRow limitText">
        {{ $t('ListCardItem.BetLimit4') }} : {{ toCurrency(cartData.BetMaxEvt) }}
      </div>
      <div
        v-if="cartData.ctAPIMinus1 === 1 && !(cartData.ctMinus1 === 1 && (toCurrency(cartData.BetMaxEvt) === toCurrency(cartData.BetMax2)))"
        class="cardContentBlockRow limitText">
        {{ $t('ListCardItem.BetLimit') }} : {{ toCurrency(cartData.BetMaxEvt) }}
      </div>
      <div
        v-if="isShowStatusData && childIndex === 0 && !cartData.isSuccessMode"
        class="cardContentBlockRow overText"
      >
        {{ cartData.statusMessage }}
      </div>
      <div
        v-if="cartData.isSuccessMode && childIndex === 0 && panelMode === PanelModeEnum.normal"
        class="cardContentBlockRow"
        style="color: green"
      >
        <img class="betResultImgIcon" src="@/assets/img/common/betView/ok.png" />
        {{ $t('common.tradeSuccess') }}
      </div>
      <div
        v-if="(isShowMinText || isMinFixed) && childIndex === 0"
        class="cardContentBlockRow overText"
      >
        {{ $t('Common.BetMinTip') }}
      </div>
      <div
        v-else-if="(isShowMaxText || isMaxFixed) && childIndex === 0"
        class="cardContentBlockRow overText"
      >
        {{
          isMaxFixed === 2 || isShowMaxText === 2 ? $t('Common.BetMaxTip2') : $t('Common.BetMaxTip')
        }}
      </div>
      <BetResultBlock v-if="childIndex === 0" :panelMode="panelMode" :cartData="cartData" />
      <mBetKeyboard
        v-if="MOBILE_APP && isShowKeyboard && panelMode === PanelModeEnum.normal"
        :isShowMaxChip="isShowMaxChip"
        :theMaxChipValue="theMaxChipValue"
        @Add="(data) => $emit('Add', data)"
        @Assign="(data) => $emit('Assign', data)"
      />
    </div>
    <div v-if="isShowBlackMask" class="blackMaskErrorBlock">
      <div class="blackMaskText">
        {{ $t('ListCardItem.ItemIsClosePlzRemove') }}
      </div>
    </div>
    <div
      v-if="childIndex === 1 && strayErrors.includes(cartData.GameID)"
      class="blackMaskErrorBlock2"
    />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import BetResultBlock from '@/components/BetResultBlock';
  import mBetKeyboard from '@/components/mBetKeyboard';
  import { MOBILE_APP } from '@/config';
  import { PanelModeEnum } from '@/config/bet';
  import { toCurrency } from '@/config/balance';
  import { openNewWindow } from '@/config/casino';

  export default {
    components: {
      BetResultBlock,
      mBetKeyboard,
    },
    props: {
      isShowMaxChip: {
        type: Boolean,
        default: false,
      },
      theMaxChipValue: {
        type: Number,
        default: 0,
      },
      cartData: {
        type: Object,
        default: null,
      },
      cartIndex: {
        type: Number,
        default: null,
      },
      childIndex: {
        type: Number,
        default: null,
      },
      currShowKeyboardIndex: {
        type: Number,
        default: -1,
      },
      listCardItemClassJudge: {
        type: String,
        default: '',
      },
      isControlByBetSingle: {
        type: Boolean,
        default: false,
      },
      isShowMinText: {
        type: Boolean,
        default: false,
      },
      isShowMaxText: {
        type: [Boolean, Number],
        default: false,
      },
      wholeFocusTarget: {
        type: Number,
        default: null,
      },
      strayErrors: {},
    },
    data() {
      return {
        MOBILE_APP,
        PanelModeEnum,
        initShowCutLine: null,
        initLiveScore: null,
        focusTarget: null,
        isMaxFixed: false,
        isMinFixed: false,
        isCleaning: false,
        maxFixedTimer: null,
        minFixedTimer: null,
      };
    },
    computed: {
      ...mapState(['userBalance']),
      ...mapGetters(['isLimit2Show']),
      isShowScore() {
        const { GameTypeID, HomeScore, CatID, WagerTypeID } = this.cartData;
        if (GameTypeID === 2 && HomeScore && [1, 31].includes(CatID) && [101].includes(WagerTypeID))
          return true;
        return false;
      },
      isShowStatusData() {
        return this.$store.state.BetCart.isShowStatusData;
      },
      triggerListCardItemClear() {
        return this.$store.state.BetCart.triggerListCardItemClear;
      },
      playMethodNameSupportChangeJudge() {
        const cutline = this.displayData.showCutLine;
        if (this.initShowCutLine !== cutline) {
          if (this.cartData.WagerTypeID !== 110) {
            this.$store.commit('BetCart/updateBetCartListDataChangeByGameID', this.cartData.GameID);
          }
          if (this.initShowCutLine.substring(1) === cutline.substring(1)) return '';
          return 'playMethodNameSupportChange';
        } else {
          return '';
        }
      },
      scoreChangeJudge() {
        if (this.initLiveScore !== this.liveScoreText) {
          return 'ScoreColorChange';
        } else {
          return '';
        }
      },
      liveScoreText() {
        if (this.liveScore('home') === '' || this.liveScore('away') === '') {
          return '';
        } else {
          return `(${this.liveScore('home')}:${this.liveScore('away')})`;
        }
      },
      selectGameType() {
        return this.$store.state.Game.selectGameType;
      },
      displayData() {
        return this.cartDataToDisplayData(this.cartData);
      },
      isShowBlackMask() {
        return (
          this.cartData.Status !== 1 || this.cartData.EvtStatus !== 1 || this.cartData.isForceLock
        );
      },
      includePrincipal() {
        return this.$store.state.Setting.UserSetting.includePrincipal;
      },
      showBetCartList() {
        return this.$store.getters['BetCart/showBetCartList'];
      },
      isShowKeyboard() {
        // 單項投注 & 點擊了此item的input
        return this.childIndex === 0 && this.currShowKeyboardIndex === this.cartIndex;
      },
      panelMode() {
        return this.$store.state.BetCart.panelMode;
      },
      isAddNewToChart() {
        return this.$store.state.BetCart.isAddNewToChart;
      },
      betInfo() {
        return this.$store.state.Game.betInfo;
      },
      realBetMax() {
        return this.$lib.BetMaxLimit(this.cartData.BetMax, this.cartData.BetMaxEvt);
      },
    },
    watch: {
      triggerListCardItemClear() {
        this.initShowCutLine = this.displayData.showCutLine;
        this.initLiveScore = this.liveScoreText;
        this.$store.commit('BetCart/updateBetCartListOriginShowOddByGameID', {
          gameID: this.cartData.GameID,
          newShowOdd: parseFloat(this.displayData.showOdd),
        });
      },
      liveScoreText() {
        if (this.initLiveScore === null && this.liveScoreText !== '') {
          this.initLiveScore = this.liveScoreText;
        }
      },
      // 監聽外部總控制
      wholeFocusTarget: {
        handler(target) {
          this.focusTarget = target;
        },
        immediate: true,
      },
    },
    methods: {
      toCurrency,
      liveScore(type) {
        if (this.cartData.CatID === 1 || this.cartData.CatID === 31) {
          if (type === 'home') {
            if (this.cartData?.HomeScore !== undefined) {
              return this.cartData.HomeScore;
            } else {
              return '';
            }
          } else {
            if (this.cartData?.AwayScore !== undefined) {
              return this.cartData.AwayScore;
            } else {
              return '';
            }
          }
        } else {
          return '';
        }
      },
      showBetTitle(showBetTitle) {
        const { WagerTypeID, OULineName } = this.cartData;
        if (WagerTypeID === 116 && OULineName) {
          return OULineName;
        }
        if (showBetTitle === 'other') {
          return this.$t('Bold.Other');
        } else {
          return showBetTitle;
        }
      },
      openLiveTV(url) {
        window.open(url);
      },
      betAmountBlur(GameID) {
        this.$emit('lastBlurInputEvent', {
          name: 'rowItem',
          from: 'betAmount',
          GameID,
          clear: this.isCleaning,
        });
        this.isCleaning = false;
      },
      winAmountBlur(GameID) {
        this.$emit('inputRowItemChangeHandler');
        this.$emit('lastBlurInputEvent', {
          name: 'rowItem',
          from: 'winAmount',
          GameID,
          clear: this.isCleaning,
        });
        this.isCleaning = false;
      },
      onCardInputClick() {
        this.$emit('onCartListItemKeyboardShow', this.cartIndex);
      },
      cancelSingleHandler(gameId) {
        this.$emit('cancelSingleHandler', gameId);
      },
      cartDataToDisplayData(cartData) {
        return this.$SportLib.cartDataToDisplayData(cartData);
      },
      inputRowItemChangeHandler(cart) {
        const { userBalance, maxFixedTimer } = this;
        const { betAmount, BetMax } = cart;
        if (betAmount === '') {
          cart.betAmount = null;
          cart.winAmount = null;
        } else {
          const max = Math.min(userBalance, BetMax);
          if (betAmount > max) {
            cart.betAmount = max;
            this.isMaxFixed = true;
            if (max == userBalance) {
              this.isMaxFixed = 2;
            }
            if (maxFixedTimer) {
              clearTimeout(maxFixedTimer);
            }
            this.maxFixedTimer = setTimeout(() => {
              this.isMaxFixed = false;
            }, 2000);
          }
        }
        this.$emit('inputRowItemChangeHandler');
      },
      inputRowItemWinAmountChangeHandler(cart) {
        const { userBalance, maxFixedTimer } = this;
        const { winAmount, BetMax } = cart;
        if (winAmount === '') {
          cart.betAmount = null;
          cart.winAmount = null;
        } else {
          const max = Math.min(userBalance, BetMax);
          const displayData = this.cartDataToDisplayData(cart);
          const odds = parseFloat(displayData.showOdd);
          const betAmount = (winAmount / odds).toFixed();
          if (betAmount > max) {
            cart.betAmount = max;
            this.isMaxFixed = true;
            if (max == userBalance) {
              this.isMaxFixed = 2;
            }
            if (maxFixedTimer) {
              clearTimeout(maxFixedTimer);
            }
            this.maxFixedTimer = setTimeout(() => {
              this.isMaxFixed = false;
            }, 2000);
          } else {
            cart.betAmount = betAmount;
          }
        }
        this.$emit('inputRowItemChangeHandler');
      },
      playBetOddClassJudge(OriginShowOdd, NowShowOdd) {
        if (parseFloat(OriginShowOdd) < parseFloat(NowShowOdd)) {
          this.$store.commit('BetCart/updateBetCartListDataChangeByGameID', this.cartData.GameID);
          return 'highOddChangeStyle';
        } else if (parseFloat(OriginShowOdd) > parseFloat(NowShowOdd)) {
          this.$store.commit('BetCart/updateBetCartListDataChangeByGameID', this.cartData.GameID);
          return 'lowOddChangeStyle';
        } else {
          return '';
        }
      },
      showOddValue(oddValue) {
        if (this.includePrincipal) {
          return this.$lib.toDecimal(this.$lib.trunc(parseFloat(oddValue) + 1));
        } else {
          return oddValue;
        }
      },
      showOddValue2(oddValue) {
        if (this.includePrincipal) {
          return this.$lib.toDecimal(this.$lib.trunc(parseFloat(oddValue) + 1));
        } else {
          return this.$lib.toDecimal(this.$lib.trunc(oddValue));
        }
      },
      onInputFocus(typeFrom) {
        const { BetMax } = this.cartData;
        this.$emit('inputFocusEvent', {
          from: typeFrom,
          BetMax,
        });
        if (this.MOBILE_APP) {
          // 強制不調用手機虛擬鍵盤
          document.activeElement.blur();
        }
        // 設定 focusTarget
        if (typeFrom === 'betAmount') {
          this.focusTarget = 1;
        } else if (typeFrom === 'winAmount') {
          this.focusTarget = 2;
        }
      },
      clearItemInputByIndex(index) {
        this.$emit('clearItemInputByIndex', index);
        this.isCleaning = false;
      },
      showOddsHistory() {
        const { GameID, wagerPos } = this.cartData;
        if (window.self !== window.top) {
          openNewWindow('OddsHistory', '_blank', { GameID, WagerPos: wagerPos });
        } else {
          const { href: url } = this.$router.resolve({
            path: 'OddsHistory',
            query: { GameID, WagerPos: wagerPos },
          });
          if (url) window.open(url);
        }
      },
      doCleaning() {
        this.isCleaning = true;
      },
    },
    created() {
      this.initShowCutLine = this.displayData.showCutLine;

      if (this.liveScoreText !== '') {
        this.initLiveScore = this.liveScoreText;
      }
    },
    mounted() {
      // 手機預設要focus
      if (this.MOBILE_APP && (this.isControlByBetSingle || this.$refs.BetAmountInput)) {
        this.$nextTick(() => {
          this.onInputFocus('betAmount');
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import './ListCardItem.scss';

  .livetv {
    position: absolute;
    top: 8px;
    right: 48px;
    width: 28px;
    height: 28px;
    cursor: pointer;

    img {
      width: 28px;
      height: 28px;
    }
  }

  .chart {
    position: absolute;
    top: 8px;
    right: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
      filter: brightness(20%);
      animation: mychart 1s ease-in-out 0s infinite alternate;

      &:hover {
        filter: brightness(100%);
        transform: scale(1.2, 1.2);
      }
    }
  }

  @keyframes mychart {
    100% {
      filter: brightness(100%);
      transform: scale(1.2, 1.2);
    }
  }

  .ScoreColor {
    font-weight: 700;
  }

  .blackMaskErrorBlock2 {
    position: absolute;
    background-color: rgba(255, 0, 0, 0.13);
    width: 100%;
    height: 100%;
    z-index: 999;
    left: 0;
    top: 0;
  }
</style>
